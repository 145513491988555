import lottie from 'lottie-web';

(function () {
  'use strict';

  const animations = [];

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {

    const observer = new IntersectionObserver(handleObserve, {})

    fetch('/lottie', {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((response) => {
        const animationsConfig = response.map((item) => {
          return {
            container: document.querySelector(`#${item.target}`),
            path: item.path
          }
        }).filter((item) => item.container !== null);

        animationsConfig.forEach((item) => {
          animations.push(lottie.loadAnimation({
            ...item,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            rendererSettings: {
              className: 'animation__item',
              progressiveLoad: true,
              preserveAspectRatio: 'xMidYMid slice'
            }
          }));
        });
        animationsConfig.forEach((animation) => observer.observe(animation.container));
      });
  }

  function handleObserve(entries) {
    entries.forEach((entry) => {
      const { target, isIntersecting } = entry;
      const animation = animations.find((anim) => anim.wrapper === target);

      if (isIntersecting) {
        animation.play();
      }

      if (!isIntersecting) {
        animation.pause();
      }
    });
  }
})();
